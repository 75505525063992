.sso-redirect{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        font-size: 16px;
        margin-right: 4px;
    }
}