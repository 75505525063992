#date-picker_schedule{
    .react-datepicker-wrapper{
        width: 100%;
    }
}

.EmojiPickerReact{
    top: 100%;
    left: 100% !important;
    transform: translateX(-100%)!important;
}